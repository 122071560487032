import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Profile from "../pages/Authentication/Profile"
import OutLogin from "../pages/Authentication/OutLogin"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Redirection from "../pages/Authentication/Redirection"
import DetailIns from "../pages/Authentication/DetailIns"
import CheckIns from "../pages/Authentication/CheckIns"
import EditIns from "../pages/Authentication/EditIns"
import ImpressionInscription from "../pages/Authentication/ImpressionInscription"
import ResetPassword from "../pages/Authentication/ResetPassword"


// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Emploi
import Emploi from "../pages/Emploi/Emploi"


//Documents
import Documents from "../pages/Documents/index"
import Controle from "../pages/Controle/index"


import CalendrierExamenPrinc from "../pages/CalendrierExamen/CalendrierExamenPrinc"

//Demande
import Demande from "../pages/Demands/index"
import AddDemande from "../pages/Demands/AddDemande"

// Result
import Result from "../pages/Result/Result"
import CalendrierDS2 from "../pages/CalendrierDS2/CalendrierDS2";
// import CalendrierExamenControle from "../pages/CalendrierExamenControle/CalendrierExamenControle";
import EmploiExamenControle from "../pages/CalendrierExamenControle/EmploiExamenControle";
import Mastere from "../pages/Mastere/Mastere";
import DocumentsEmploi from "../pages/Emploi/DocumentsEmploi";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // profile
  { path: "/profile", component: UserProfile },

  //Emploi
  { path: "/Emploi", component: Emploi },
  
  //Documents
  { path: "/Documents", component: Documents },
  { path: "/Controle", component: Controle },

  { path: "/CalendrierDS2", component: CalendrierDS2 },
  { path: "/CalendrierExamen", component: CalendrierExamenPrinc },
  { path: "/CalendrierExamenControle", component: EmploiExamenControle },

    //Demande
    { path: "/Demands", component: Demande },
    { path: "/AddDemande", component: AddDemande },
//Mastere
  { path: "/Mastere", component: Mastere },
  // Result
    { path: "/Result", component: Result },  

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/ProfileEtudiant", component: Profile },
  { path: "/OutLogin", component: OutLogin },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/Redirection", component: Redirection },
  { path: "/DetailIns", component: DetailIns },
  { path: "/CheckIns", component: CheckIns },
  { path: "/EditIns", component: EditIns },
  { path: "/ImpressionInscription", component: ImpressionInscription },
  { path: "/ResetPassword/:token", component: ResetPassword },

]

export { publicRoutes, authProtectedRoutes }
