import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Spinner } from "reactstrap"
import API from "../../apis"
import ISGGB from "../../assets/images/ISGB.jpg"
import tunisie_rep from "../../assets/images/tn_rep.png"

const EmploiTemps = props => {
  if (localStorage.getItem("userAuthEtudiant")) {
    var userAuthEtudiant = JSON.parse(localStorage.getItem("userAuthEtudiant"))
    var etudiant_id = userAuthEtudiant.user.id_etudiant

  }
  const [loading, setLoading] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [group, setGroup] = useState([])
  const [imp, setImp] = useState(0);
  const [rattrappage, setRattrappage] = useState([]);
  const [ds, setDs] = useState([]);
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var anneeUniversitaire=anneeScolaire.Annee.anneescolaire
  var annee = anneeScolaire.Annee.id
  useEffect(async () => {
    const resE = await API.post("emploi/get_emploi_by_etudiant", {
      etudiant_id: etudiant_id,
      semestre_id: props.semestre_value,annee_id:annee
    }).then(resE => {
      if (resE.data.errNum == 403) {
        setImp(0)
        setQuarts([])
        setJours([])
        setLoading(true)
      } else {
        setImp(1)
        setQuarts(resE.data.emploi[1].quarts)
        setJours(resE.data.emploi[1].jours)
        setGroup(resE.data.emploi[1].group)
        setRattrappage(resE.data.emploi[1].ratt)
        setDs(resE.data.emploi[1].ds)
        setLoading(true)
      }
    })
  }, [props.semestre_value])

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div className="container">
            <div className="row" style={{ paddingTop: "30px" }}>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                République Tunisienne
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {"Ministère de l'enseignement supérieur,"}
                </div>
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  {" "}
                  de la Recherche Scientifique
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  Université de Gabès
                </div>
                <div
                  className="col"
                  style={{
                    marginLeft: 0,
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"L'INSTITUT SUPERIEUR DE GESTION GABES"}
                </div>
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "70%" }} src={ISGGB} />
              </div>
              <div
                className="col"
                style={{ position: "relative", textAlign: "center" }}
              >
                <img style={{ width: "28%" }} src={tunisie_rep} />
              </div>
              <div
                className="col"
                style={{ fontSize: "12px", textAlign: "center" }}
              >
                الجمهورية التونسية
                <div
                  className="col"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  وزارة التعليم العالي والبحث العلمي
                </div>
                <div
                  className="col"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingTop: "10px",
                  }}
                >
                  جامعة قابس
                </div>
                <div
                  className="col"
                  style={{
                    textAlign: "center",
                    marginRight: 0,
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  المعهد العالي للتصرف بقابس{" "}
                </div>
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                marginTop: "0.1%",
                color: "black",
              }}
            />
            <div className="row">
              <div
                className="col-md-4"
                style={{
                  display: "flex",
                  fontSize: "13px",
                  justifyContent: "start",
                }}
              >
                {imp == 1 ?(
                    <a
                        href={
                            "https://scolarite.backcresus-institut.ovh/api/impression_group_shedule_etudiant/" + etudiant_id + "/" + props.semestre_value +"/"+annee
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                      <button className="btn btn-primary btn btn-primary">
                        <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                        Emploi
                      </button>
                    </a>
                ):(null)}

              </div>
              <div
                className="col-md-4"
                style={{
                  display: "flex",
                  fontSize: "15px",
                  justifyContent: "center",
                }}
              >
                <span style={{ paddingRight: "4px" }}>Groupe:</span>
                <span style={{ fontWeight: "bold" }}>{group}</span>
              </div>
              <div className="col-md-4">
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "end",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>
                    Année Universitaire:{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>{anneeUniversitaire}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "13px",
                    justifyContent: "end",
                  }}
                >
                  <span style={{ paddingRight: "4px" }}>Semestre:</span>
                  <span style={{ fontWeight: "bold" }}>
                    {props.semestre_label}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th></th>
                    {quarts.map(el => (
                      <th key={el.id_quart} style={{ whiteSpace: "nowrap" }}>
                        {el.label_quart}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jours.map((elem, index) => (
                    <tr key={elem.id_jour}>
                      <td style={{ fontWeight: "bold" }}>{elem.label_jour}</td>
                      {elem.seances.map((element, indice) => (
                        <td
                          key={indice}
                          style={{ padding: "0px" }}
                          className="td-emploi"
                        >
                          {element.options.map((elle, ind) => (
                            <div
                              key={ind}
                              style={{
                                padding: "5px 5px 5px 5px",
                                cursor: "pointer",
                                borderBottom:
                                  ind == 0 && element.options.length > 1
                                    ? "1px solid #eff2f7"
                                    : null,
                              }}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_matiere}
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_enseignant}
                              </p>
                              {elle.type == 3 ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {elle.group_tp}
                                </p>
                              ) : null}
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_salle}
                              </p>
                              {elle.rattrapage == "1" ? (
                                <div className="div-icon">
                                  <span className="icon-rattrapage">R</span>
                                </div>
                              ) : null}
                              {element.type == 1 ? (
                                <div className="div-icon">
                                  <span className="icon-cour">C</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 2 ? (
                                <div className="div-icon">
                                  <span className="icon-td">TD</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 3 ? (
                                <div className="div-icon">
                                  <span className="icon-tp">TP</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : (
                                <div className="div-icon">
                                  <span className="icon-autre">A</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          ))}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <h2 style={{color:"red"}}>NB :</h2>
              {rattrappage.map((elem, index) => (
                  <h5  key ={index}>
                    Le <span style={{color:"red"}}>{elem.date} </span>, dans la salle  <span style={{color:"red"}}>{elem.salle} </span> à <span style={{color:"red"}}> {elem.quart} </span> on a un rattrapage de la matière <span style={{color:"red"}}> {elem.matiere} </span>.
                    </h5>
              ) )}
              <h2 style={{color:"red"}}>NB :</h2>
              {ds.map((elem, index) => (
                  <h5  key ={index}>
                    Le <span style={{color:"red"}}>{elem.date} </span>, dans la salle  <span style={{color:"red"}}>{elem.salle} </span> à <span style={{color:"red"}}> {elem.quart} </span> on a un examen DS de type  <span style={{color:"red"}}>{elem.type_examens}</span>  de la matière <span style={{color:"red"}}> {elem.matiere} </span>.
                    </h5>
              ) )}

            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default EmploiTemps
EmploiTemps.propTypes = {
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  semestre_label: PropTypes.object,
}
